import {BASE_URL } from "../constants";
import { apiSlice } from "./apiSlice";
export const usersApiSlice=apiSlice.injectEndpoints({
    endpoints:(builder)=>({
        login:builder.mutation({
            query:(data)=>({
                url:`/api/admin`,
                method:'POST',
                body:data

            })

        }),
      

        queryEmail:builder.mutation(
            

            {
    
    
            query:(data)=>(
                {
    
    
                    url:`/api/admin`,
                method:'PUT',
                body:data
    
    
    
            }),
            
    
        }
        
        )
    }),
   


  
})

export const {useLoginMutation,useQueryEmailMutation}=usersApiSlice