export const Project_data=[
    
   
{

id:1,
    name:'Abbott Farm',
    category:'Farm House',
    location:'Chattarpur, New Delhi',
    images:[
        {
        img:require('../images/Abott_0.svg'),
        text:'The Abott Farmhouse is a stunning Balinese-inspired architecture seamlessly blending traditional design elements with modern luxury.'
    },
    {
        img:require('../images/Abott_1.svg'),
        text:"The elevation of the Farmhouse is a key feature, showcasing a harmonious integration of natural materials, such as stone and wood, which enhance the structure's organic appeal."
    },     {
        img:require('../images/Abott_2.svg'),
        text:'Drawing from the serene and tranquil essence of Balinese style, the farmhouse boasts open spaces, lush greenery, and water features that evoke a sense of peace and relaxation.'
    },     {
        img:require('../images/Abott_3.svg'),
        text:'Every detail reflects the calming aesthetics of Bali, creating a perfect retreat that balances elegance with comfort.'
    },     {
        img:require('../images/Abott_3.svg'),
        text:'The thoughtfully placed courtyards further enhance the indoor-outdoor connection, making this farmhouse a true sanctuary.'
    },
    // {
    //     img:require('../images/Abott_0.svg'),
    //     text:'The harmonious blend of tradition and contemporary design infuses the space with a timeless charm, catering to both relaxation and modern living.'
    // },
]
},
{

id:2,
    name:'Puneet Gupta,MTS Nagar, Jalandhar',
    category:'Residence',
    location:'Jalandhar, Punjab',
    images:[

        
    {
        img:require('../images/Puneet_0.svg'),
        text:'This residence exemplifies the timeless elegance of Neo-Classical interiors, where classical design meets modern luxury.'
    },
        {
        img:require('../images/Puneet_1.svg'),
        text:' The interiors are thoughtfully crafted to create a sophisticated yet welcoming atmosphere, with each space meticulously designed to offer both comfort and opulence.'
    },
    {
        img:require('../images/Puneet_2.svg'),
        text:'The carefully selected furniture pieces play a pivotal role in defining the character of the home.'
    },     {
        img:require('../images/Puneet_3.svg'),
        text:'Each item, from the plush sofas to the ornate tables, is chosen not just for its aesthetic appeal but for its ability to enhance the overall sense of luxury'
    },     {
        img:require('../images/Puneet_4.svg'),
        text:'The use of rich materials, intricate detailing, and subtle color palettes contribute to an environment that is both grand and inviting.'
    },
    //      {
    //     img:'',
    //     text:'The interiors of this luxurious home blend sophistication with comfort, showcasing meticulous craftsmanship and elegant design.'
    // },
]
},
{

id:3,
    name:'Saffron High Street',
    category:'Commercial complex',
    location:'Kapurthala, Punjab',
    images:[

        
    {
        img:require('../images/SHS_0.svg'),
        text:'Saffron High Street sets a new standard as the first of its kind in the city, featuring premier destinations like Cinepolis and Haldiram’s under one roof. '
    },
        {
        img:require('../images/SHS_1.svg'),
        text:'The Neo-Classical facade of Saffron High Street is a standout feature, capturing the grandeur of classical architecture while integrating it seamlessly into a modern commercial setting. '
    },
    {
        img:require('../images/SHS_2.svg'),
        text:'This project is a testament to innovative urban design, combining convenience with a touch of timeless elegance.'
    },     {
        img:require('../images/SHS_3.svg'),
        text:'The facade’s intricate details and stately proportions offer a visual delight, creating a welcoming and luxurious atmosphere for visitors.'
    },     {
        img:require('../images/SHS_4.svg'),
        text:'As a pioneering development in the city, Saffron High Street not only introduces renowned brands but also elevates the shopping and entertainment experience with its sophisticated design. '
    },   
    
    // {
    //     img:'',
    //     text:'The project reflects our commitment to creating spaces that are not only functional but also leave a lasting impression through their architectural aesthetics.'
    // },
]
},
{

id:4,
    name:'Ramada Hotel',
    category:'Hotel',
    location:'Kapurthala, Punjab',
    images:[
        {
        img:require('../images/Ram_0.svg'),
        text:'Hotel Ramada embodies the essence of classical luxury, offering guests an unparalleled experience through its meticulously designed spaces. '
    },
    
    {
        img:require('../images/Ram_1.svg'),
        text:"The banquet halls serve as the centerpiece of the hotel's event offerings, with their opulent decor and spacious layouts designed to host grand occasions in style. "
    },
    {
        img:require('../images/Ram_2.svg'),
        text:'These halls are a perfect blend of classic design elements and modern amenities, ensuring that every event is both memorable and distinguished.'
    },     {
        img:require('../images/Ram_3.svg'),
        text:'Adding to the hotel’s allure is the rooftop cafe, where guests can unwind in a stylish, elevated setting. The cafe offers stunning views and a chic ambiance, making it an ideal spot for both casual dining and special occasions.'
    },     {
        img:require('../images/Ram_4.svg'),
        text:'The hotel project stands as a testament to our dedication to creating spaces that offer both luxury and timeless appeal, ensuring every guest enjoys a truly exceptional stay.'
    },  
    //    {
    //     img:'',
    //     text:' the vanities and toilets continue the theme of luxury, with high-end fixtures and finishes that offer a sense of refinement and relaxation. '
    // },
]
},
{

id:5,
    name:'Saffron City Walk',
    category:'Commercial Complex',
    location:'Jalandhar, Punjab',
    images:[
        {
            img:require('../images/SCW_0.svg'),
            text:' The modern facade stands out with its sleek lines and stylish finishes, creating a striking visual impact that sets the tone for the entire building.'
        },
        {
        img:require('../images/SCW_1.svg'),
        text:'The complex features a G+3 structure, maximizing space and functionality while maintaining a clean and organized aesthetics. '
    },
    {
        img:require('../images/SCW_2.svg'),
        text:'Upon entering, visitors are greeted by a double-height lobby that exudes a sense of grandeur and openness.'
    },     {
        img:require('../images/SCW_3.svg'),
        text:'The lift lobby is designed with attention to detail that ensures both efficiency and aesthetic appeal.'
    },     {
        img:require('../images/SCW_4.svg'),
        text:'The well-lit passages throughout the building enhance the user experience, providing bright and welcoming corridors that are both functional and inviting.'
    },  
    
    // {
    //     img:'',
    //     text:'This commercial complex is more than just a place of business; it is a thoughtfully designed environment that combines modern aesthetics with practical features, making it an ideal space for companies looking to make a strong statement.'
    // },
]
},
{

id:6,
    name:'Luxotic homes',
    category:'Residential Apartments',
    location:'Jalandhar, Punjab',
    images:[
        {
            img:require('../images/Lux_0.svg'),
            text:'These residential apartments redefine modern living with their carefully crafted interiors and contemporary design. Available in 3BHK and 4BHK configurations, each unit is designed to provide a luxurious and comfortable lifestyle, combining functionality with aesthetic appeal.'
        },
        {
        img:require('../images/Lux_1.svg'),
        text:'The modern interiors are a highlight of these apartments, featuring high-quality finishes and thoughtful design elements that create a sophisticated yet welcoming atmosphere. '
    },
    {
        img:require('../images/Lux_2.svg'),
        text:'From the spacious living areas to the elegant bedrooms, every space is meticulously designed to reflect a sense of luxury and comfort.'
    },     {
        img:require('../images/Lux_3.svg'),
        text:'Well-lit spaces are a key feature throughout the apartments, with large windows and strategically placed lighting that enhance the natural light and create a bright, airy environment. The use of ambient lighting further adds to the sense of warmth and coziness, making each room feel inviting and stylish.'
    },     {
        img:require('../images/Lux_4.svg'),
        text:'The exterior of the building features a modern facade, seamlessly blending with the contemporary interiors to create a cohesive and striking appearance. '
    },  
    //    {
    //     img:'',
    //     text:'The building facade not only enhances the aesthetic appeal of the property but also makes a strong architectural statement in the surrounding area.'
    // },
]
},

{

id:7,
    name:"Mr Gurvinder Singh‘s Residence",
    category:'Residence',
    location:'Kapurthala, Punjab',
    images:[
        {
            img:require('../images/Gur_0.svg'),
            text:'The home features a modern facade that presents a sleek and sophisticated exterior, setting the stage for the refined interiors that lie within.'
        },
        {
        img:require('../images/Gur_1.svg'),
        text:'Inside, the Neo-Classical interiors are the highlight of the residence, combining timeless design with modern comfort.  '
    },
    {
        img:require('../images/Gur_2.svg'),
        text:'Each room is thoughtfully designed to reflect the grandeur of neo-classical architecture while incorporating the conveniences of modern living.'
    },     {
        img:require('../images/Gur_3.svg'),
        text:'This project exemplifies the seamless integration of a modern facade with Neo-Classical interiors, resulting in a residence that is both aesthetically pleasing and functionally superior.'
    },     {
        img:require('../images/Gur_4.svg'),
        text:'It is a home that truly embodies the best of both worlds, offering a luxurious interiors and comfortable living environment.'
    },  
    //    {
    //     img:'',
    //     text:'It is a home that truly embodies the best of both worlds, offering a luxurious interiors and comfortable living environment.'
    // },
]
},
]