import React,{useState} from 'react';

import Grid from '@mui/material/Grid';
import './header.css'
import LOGO from "../../../images/Logo.png"
import Navbar from './navbar';
import { IoMenu } from "react-icons/io5";
import TemporaryDrawer from './D6_Sl_Drawer';
function Header() {
       
    const [Dec6_Navbar_Items1,setNavbar] = useState(false);
    const [open, setOpen] = useState(false)
 
    const Toggle=()=>{
      setOpen(!open)
    }
    const changeBackground = () => {
        if(window.scrollY >= 50)
        {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    };
    window.addEventListener('scroll',changeBackground);

        return(
            <>
            <nav className='header_strip'>
                <Grid style={{height:'100%'}} container>
           <Grid alignContent={'center'} item  className='icon_gridM' sm={12} >
        <div  style={{backgroundPosition:'center'}} onClick={()=>window.location.href='/'} className='icon_head' src={LOGO}></div>
    </Grid>

   
                </Grid>
             
            </nav>
            <nav className={Dec6_Navbar_Items1?'D6_SL_Navbar_ItemsA':'D6_SL_Navbar_Items'}>
<Grid container className='Grid_MC'>
    <Grid container className='grid_contSub' sm={3}>
        <Grid container className='Grid_TC' sm={6}>
      
      <Grid item  className='icon_gridM2'  xs={6}  sm={12}>
        <div onClick={()=>window.location.href='/'} className='icon_head' ></div>
    </Grid>
    <Grid item className='grid_ham'xs={6}  sm={12}>

{/* <IoMenu  onClick={()=> setOpen(!open) }  className='icon_ham'/> */}
{/* <i class="fas fa-align-justify icon_ham" onClick={()=> setOpen(!open) } ></i>    */}
</Grid>
    </Grid>
 
    </Grid>
    <Grid item style={{alignSelf:'center'}} className='grid_multi' sm={12} xs={12}>
    <TemporaryDrawer Toggle={Toggle} open={open}  />
   {/* <Navbar  /> */}
     </Grid>
    
    </Grid>                  
            </nav>
            </>
        );
    }
    
    export default Header;