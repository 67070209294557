import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore' ;

import Accordion from '@mui/material/Accordion';
import{ AccordionSummary,AccordionDetails} from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import './D6_Sl_Drawer.css'
export default function TemporaryDrawer(props) {

  const [open,setOpen]=React.useState(true)


  

  const Open=()=>{
    setOpen(!open)
  }
const Toggle=()=>{
  props.Toggle()
}
  const list = (anchor) => (
    <Box
      className='box_W'
      role="presentation"
      onClick={Open}
      onKeyDown={Open} 
      
    >
      <List >
        <ListItem style={{paddingBottom:'10vh'}} onClick={()=>{Toggle()}}>
        <ListItemIcon style={{cursor:'pointer',paddingBottom:'2px',color:'white',width:'100%',paddingTop:'20px'}}>
      <CloseIcon  />
      </ListItemIcon>
      </ListItem>


    


        {(['Home','About us','Portfolio',"Services" ,"Contact","Careers"]).map((text, index) => (
          // <Accordion  onClick={()=>window.location.pathname=`${text=='Home'?'/':text=='About us'?'/aboutus':text=='Contact'?'/contactus':`/${text.toLocaleLowerCase()}`}`} button key={text}>
          
          <div onClick={()=>window.location.pathname=`${text=='Home'?'/':text=='About us'?'/aboutus':text=='Contact'?'/contactus':`/${text.toLocaleLowerCase()}`}`} style={{textAlign:'-webkit-center'}} key={index}>
          <Typography className='typo_menu' >
{text}
          </Typography>
          <div className='typo_menu_line' />
          </div>


        ))}
  
     
    
      </List>

    </Box>
  );

  return (
    // <div>
    <>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={'right'}
            open={props.open}
            onClose={Open}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      </>
    // </div>
  );
}