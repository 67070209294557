import { Chip, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import './card.css'
import { FaInstagram, FaLocationDot } from 'react-icons/fa6'
import { HiOutlineMailOpen } from 'react-icons/hi'
import { FaMobileAlt } from 'react-icons/fa'

import { toast } from 'react-toastify'
export default function Card() {
    const[blob1,setBlob]=useState(null)

      const copyToClipboard = (valu) => {

   const el = document.createElement('textarea');
   el.value ='https://arch6.in/card';
   document.body.appendChild(el);
   el.select();
   document.execCommand('copy');
   document.body.removeChild(el);
toast.success('Card copied')

 };



  return (
    <div style={{textAlign:'-webkit-center',paddingTop:20}}>
        
        <Paper elevation={6} className='ppr_card'>
<div style={{display:'flex',flexDirection:'row'}}>
<div style={{width:'50%'}}>

<Typography className='ppr_card_head'>
    Pallavi Gupta
</Typography>
<Typography  className='ppr_card_sub'>
    Principal Architect
</Typography>

</div>
<div style={{width:'50%',textAlignLast:'right'}}>
    <div style={{paddingRight:10}}>
    <img src={require('../../images/card1.png')} className='card_img' />
    </div>
    <div>
    <img src={require('../../images/card2.png')} className='card_img2' />
    </div>
    {/* <div>
    <img src={require('../../images/qr.png')} className='card_img3' />
    </div> */}
</div>
</div>
<div  className='divider'/>

<div  className='div_icons'>

<div style={{display:'flex',flexDirection:'row'}}>
    <div style={{width:'70%'}}>
    <div onClick={()=>(window.open('https://www.instagram.com/_arch_6_/'))} className='sub_div_icons'>
    <FaInstagram className='card_icon'/>
    <Typography className='social_txt'>
_arch_6_
    </Typography>
    </div>
    <div onClick={()=>window.open('mailto:ar.pallavigupta@arch6.in')} className='sub_div_icons1'>
    <HiOutlineMailOpen className='card_icon'/>
    <Typography className='social_txt'>
ar.pallavigupta@arch6.in
    </Typography>
    </div>
    <div onClick={()=>window.open('tel:+91-9877267509')} className='sub_div_icons'>
    <FaMobileAlt className='card_icon'/>
    <Typography className='social_txt'>
+91-9877267509
    </Typography>
    </div>
    </div>
    <div style={{alignContent:'center',width:'30%'}}>
    <img src={require('../../images/qr.png')} className='card_img3' />
 
    </div>
    </div>
    <div style={{width:'100%'}} onClick={()=>window.open('https://maps.app.goo.gl/naUA2vizqkkkCv1e9')} className='sub_div_icons1'>
    <FaLocationDot className='card_icon'/>
    <Typography className='social_txt'>
3745/1 Model Town Extension, Ludhiana, Punjab 141005
    </Typography>
    </div>

</div>

{/* <div className='div_icons2'>

</div> */}
        </Paper>

        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',paddingTop:'3vh'}}>


<Chip  onClick={()=>copyToClipboard()} className='chip_secondary' label='Copy card' />

            </div>

            <p onClick={()=>window.open('https://arch6.in',"_blank")} className='txt_card'>
            &#169;2024 Arch6
            </p>
    </div>
  )
}
