import React from "react";
import Below_crousel from "./home_belowCrousel/below_crousel";
import Home_crousel2 from "./home_crousel/home_crousel2";
import { Helmet } from "react-helmet";
import './home_belowCrousel/below_crousel.css'
import Home_crousel from "./home_const/home_crousel";
export default function Homepage(){
    return(
        <>
         <Helmet>
 
        <title>Home | Arch6</title>
       
        <meta
          name="description"
          content={'With every design, we strive to deliver results that are both timeless and resilient, meeting the highest standards of quality'}
        />


    <meta name="keywords" content={'design,luxary,resilient,interiors,architecture,products'} />
 
    {/* <link rel="canonical" href="https://www.dec6.in/Insights" /> */}

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Home | Arch6" />
       
    <meta
    itemprop="description"
    content={'With every design, we strive to deliver results that are both timeless and resilient, meeting the highest standards of quality'}
  />
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
        {/* <meta property="og:url" content="https://fillynedia.com/blog" /> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Home | Arch6" />
      
    <meta
          property="og:description"
          content={'With every design, we strive to deliver results that are both timeless and resilient, meeting the highest standards of quality'}
        />
        
      
      

      
       
      </Helmet>

<Home_crousel />
<div style={{}} className="home_below_bg_const" >
  
  {/* <h1 style={{color:'white',margin:0,paddingTop:'63vh',fontFamily:'var(--secondaryfont)',paddingLeft:'5vw',fontSize:'2.4rem'}}>
    arch6.in
    
    </h1> */}

    <img src={require('../../images/Logo_n.png')} style={{paddingLeft:'5vw',height:60,paddingTop:'63vh'}} />
    <h4 style={{color:'white',paddingTop:'3vh',fontFamily:'var(--secondaryfont)',paddingLeft:'5vw'}}>
We're under construction. Please come back for an update soon.
    
    </h4>
  </div>

        {/* <Home_crousel2 />
        <Below_crousel /> */}
        {/* <SwiperC /> */}
        </> 
    )
}