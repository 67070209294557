import React, { useState,useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import './home_crousel.css'


import {  Grid, Typography } from '@mui/material';
// import { BlogsData } from "../Blog/BlogData";


import Aos from 'aos';
import 'aos/dist/aos.css'; 
import { Project_data } from "../../../data/projects_data";
const NextArrow = ({ onClick }) => {
  return (
    <div className="nextArrow" onClick={onClick}>
      <BsChevronRight />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="prevArrow" onClick={onClick}>
      <BsChevronLeft />
    </div>
  );
};



export default function Home_crousel() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
}, []); 


  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // nextArrow: <NextArrow onClick />,
    // prevArrow: <PrevArrow onClick />
  };

  return (

      <div className="D6_SL_SC_Slider_Div">
    <Slider dots {...settings} fade>

   {Project_data.map((val,idx)=>
   <div  className="D6_SL_Img1_div1">
   <Grid container >
<img src={val.images[0].img.default}   className='blog_bgC' />
     {/* <Grid container  
        sm={12} xs={12}   style={{backgroundImage:`linear-gradient(45deg,black, transparent),url(${val.images[0].img.default})`}} className='blog_bgC'> 




     </Grid> */}
     <div className="overlay1"/>
     
     
   </Grid>
      

    
   </div>)}
       
      
       

       
    </Slider>
    </div>
  );
}