import './App.css';
import {Routes,Route, BrowserRouter} from "react-router-dom";
import ScrollNPT from './components/common/STTP/scroll_Top';
import STTopARROW from './components/common/Scroll_To_Top_Arrow/PP_STTop';
import D6_SL_Footer from './components/common/footer/D6_SL_Footer';
import Header from './components/common/header/header';
import Homepage from './components/homepage/homepage';
import Above_footer from './components/common/footer/Above_footer';
import Services from './components/services/services';
import Portfolio from './components/portfolio/portfolio';
import Careers from './components/careers/careers';
import Query from './components/careers/query';
import About from './components/about/about';
import Particular_Portfolio from './components/portfolio/particularProject';
import Records from './components/dashboard/records';
import Signin from './components/dashboard/dashboard';
import {ToastContainer} from 'react-toastify'
import Card from './components/card/card';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
          <ToastContainer />

    <BrowserRouter>
    <ScrollNPT />
    {window.location.pathname.includes('projects')||window.location.pathname.includes('admin')||window.location.pathname.includes('card') ?null: <Header />}
    <Routes>
    <Route  path="/" element={<Homepage />}  /> 
    {/* <Route  path="/services" element={<Services />}  /> 
    <Route  path="/portfolio" element={<Portfolio />}  /> 
    <Route  path="/projects/:id" element={<Particular_Portfolio />}  /> 

    <Route  path="/careers" element={<Careers />}  /> 
    <Route  path="/contactus" element={<Query />}  /> 
    <Route  path="/aboutus" element={<About />}  /> 
    <Route  path="/admin" element={<Records />}  />  */}
    <Route  path="/card" element={<Card />}  /> 


    </Routes>
{/* {(window.location.pathname.includes('admin')||window.location.pathname.includes('card')) ?null:    <STTopARROW />
}    */}

{/* {window.location.pathname.includes('card') ?null:
<><Above_footer />
  <D6_SL_Footer />
  </> }   */}

    </BrowserRouter>
    </div>
  );
}

export default App;
